<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Transaksi</p>
    <button type="button"  class="btn btn-sm btn-dark  " @click="dataedit={}"><span class="typcn typcn-arrow-repeat"></span></button>
    <div v-if="modalbayar" class="row" style="position:fixed;top:60px;right:10px;z-index:1000;width:100vw;">
        <div class="col-sm-9" @click="modalbayar=false" style="height:100vh;"></div>
        <div class="col-sm-3">
            <div class="bg-white rounded-lg p-3 shadow animate__animated animate__bounceInDown">
                <div class="sm-form ">
                    <label for="nota">Nota</label>
                    <input type="text" disabled id="nota" name="nota" class="form-control form-control-sm" placeholder="nota" v-model="dataedit.nota" >
                </div>
                <div class="sm-form ">
                    <label for="uang">Uang : </label>
                    <input type="number" id="uang" name="uang" class="form-control form-control-sm" placeholder="uang" v-model="uang" >
                </div>
                <div class="sm-form">
                <textarea
                type="text"
                id="keterangan"
                name="keterangan"
                rows="2"
                placeholder="keterangan..."
                class="form-control md-textarea"
                v-model="dataedit.keterangan"
                ></textarea>
                </div>
                <hr>
                <p class="">total Rp. {{total}}</p>
                <p>Uang : {{uang}}</p>
                <!-- <button type="button" @click="diskon" :disabled="diskons" class="btn btn-sm btn-primary  ">Apply Diskon</button> -->
                <p v-show="uang-total">Kembalian : {{uang-total}}</p>
                <hr>
                <button type="button" @click="bayar" class="btn btn-sm btn-dark  "><span class="typcn typcn-export"></span> Bayar</button>
            </div>
        </div>
    </div>
    <div v-if="dataedit.nota">
      <form  @submit.prevent="submit">
        <div class="row justify-content-center">
          <div class=" col-sm-5 p-2" style="font-size:12px;">
            <div class="shadow p-3 rounded-lg bg-white">
            <!-- INPUT -->
            <div class="sm-form">
              <input
                type="text"
                id="id"
                name="id"
                class="form-control form-control-sm"
                placeholder="id"
                v-model="dataedit.id"
                disabled
              />
            </div>
            <table class="table table-sm table-responsive">
            <tr>        
              <td class="capitalize">tanggal</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input required type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="dataedit.tanggal" >
                  </div>
              </td>
          </tr>
            <tr>        
              <td class="capitalize">barcode</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <v-select :options="produks" label="item_data" v-model="dataedit.id_produk" @input="cekstok" :reduce="e => e.id"></v-select>
                  <!-- <select required class='form-control' v-model="dataedit.id_produk" @change="cekstok">
                  <option v-for="(item, index) in produks" :key="index+'pridils'" :value="item.id">{{item.barcode}}/{{item.nama_produk}}</option>
                  </select> -->
              </td>
          </tr>
            <tr>        
              <td class="capitalize">Jumlah</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input required type="number" @change="hitung_total" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="dataedit.jumlah" >
                  </div>
                  <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
              </td>
          </tr>
            <tr>        
              <td class="capitalize">harga</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="number" @change="hitung_total" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="dataedit.harga" >
                  </div>
              </td>
          </tr>
            <tr>        
              <td class="capitalize">diskon</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <div class="sm-form ">
                      <input type="number" @change="hitung_total" id="diskon" name="diskon" class="form-control form-control-sm" placeholder="diskon" v-model="dataedit.diskon" >
                  </div>
              </td>
          </tr>
            <tr>        
              <td class="capitalize">total</td>
              <td class="pl-2 pr-2">:</td>
              <td class="font-bold text-lg">
                  Rp. {{dataedit.total}}
              </td>
          </tr>
            <tr>        
              <td class="capitalize">Pasien</td>
              <td class="pl-2 pr-2">:</td>
              <td>
                  <select class='form-control' v-model="dataedit.id_pelanggan">
                  <option v-for="(item, index) in pelanggans" :key="index+'pridils'" :value="item.id">{{item.nama}}</option>
                  </select>
              </td>
          </tr>
          </table>
            <!-- END INPUT -->
            <!-- BUTTON PART -->
            <div class="mt-3 p-3">
              <button
                type="submit"
                class="btn btn-sm"
                :class="
                  btn == 'tambah'
                    ? 'btn-primary'
                    : btn == 'update'
                    ? 'btn-success'
                    : 'btn-danger'
                "
              >
                <span class="typcn typcn-info"></span>  Proses {{ btn }}
              </button>
              <div class="float-right">
                <button
                  type="button"
                  v-show="btn!='tambah'"
                  @click="
                    btn = 'tambah';
                    dataedit = {};
                    skip_ambil_diskon=false;
                    getNota();
                  "
                  class="btn btn-sm btn-dark"
                >
                  <span class="typcn typcn-edit"></span> Tambah
                </button>
                <button
                  type="button"
                  @click="btn = 'delete'"
                  v-show="btn!='tambah'"
                  class="ml-3 btn btn-sm btn-danger"
                >
                  <span class="typcn typcn-delete"></span> Delete
                </button>
              </div>
            </div>
            </div>
            <!-- END BUTTON PART -->
          </div>
          <div class=" col-sm-7 p-2" style="font-size:12px;">
            <div class="shadow bg-white rounded-lg p-2">
              <div v-if="datanya.length>0" class="">
              <button type="button" @click="modalbayar=true" class="btn btn-sm btn-primary  float-right"><span class="typcn typcn-export"></span> Bayar</button>
                <button type="button" @click="deleteAll" class="btn btn-sm btn-dark  "><span class="typcn typcn-eject"></span> Delete All</button>
                  <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','stok','keluar','id_user']" @selected="ambil" ref="btable" />
                   <!-- TABLE PART -->
                  <!-- END TABLE PART -->
              </div>
            </div>
          </div>
        
        </div>
      </form>
    </div>
    <div v-else>
        <div class="row justify-content-center">
          <div class="col-md-6">
                <div class="sm-form ">
                    <label for="search">cari Nota</label>
                    <input type="text" id="search" name="search" class="form-control form-control-sm" placeholder="search" v-model="search" >
                </div>
                <div class="p-3 shadow rounded-lg text-xs hover:bg-blue-300 cursor-pointer mt-2" @click="ambilnota(item)" v-for="(item, index) in td" :key="index+'notas'">
                    <p>Nota : {{item.nota}}</p>
                    <p>Tanggal : {{item.tanggal}}</p>
                    
                </div>
          </div>
        </div>
    </div>
   
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      modalbayar:false,
      dataedit: {
        jumlah:0
      },
      produks:[],
      pelanggans:[],
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:0,
      diskons:false,
      skip_ambil_diskon:false,
      id_nota:0,
      search:''
    };
  },
  computed:{
      td(){
          let data=this.notas;
          data=data.filter(e=>{
              if(e.nota.toLowerCase().indexOf(this.search)!=-1){
                  return e
              }
          })
          return data
      }
  },
  methods: {
      test(){
          alert('y')
      },
      getNota(){
          sdb.collection("app_kasir_transaksi_nota").doc().get("*","order by id").then(res => {
            this.notas=res;
              this.$forceUpdate();
          });
      },
       ambilnota(item){
          this.dataedit.nota=item.nota
          this.id_nota=item.id
          console.log(item)
          setTimeout(() => {
                this.getData();
                this.uang=parseInt(item.uang)
                this.dataedit.keterangan=item.keterangan
                this.$forceUpdate();
          }, 1000);
          this.$forceUpdate();
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD-HH-mm");
      },
     async cekstok(item){
        console.log(this.dataedit)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.dataedit.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.dataedit.diskon=res[0].diskon
         }
          this.dataedit.harga=res[0].harga
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.dataedit.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.dataedit.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.dataedit.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(){
        this.dataedit.total=parseInt(this.dataedit.harga)*this.dataedit.jumlah-((parseInt(this.dataedit.diskon)/100)*parseInt(this.dataedit.harga)*this.dataedit.jumlah)
        this.$forceUpdate();
    },
    deleteAll(){
        if(confirm('apakah yakin menghapus semua data?')){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
        }
    },
    getData(){
      sdb.collection("app_kasir_keranjang",false).doc().select(`
      select tb1.id,tb1.id_user,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb2.barcode,tb1.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb1.diskon,tb1.total
      from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.id_user='${this.$store.state.users.id}' AND tb1.posisi='nota' AND nota='${this.dataedit.nota}'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
        }
        this.$forceUpdate();
      });
      sdb.collection("app_kasir_produk").doc().get("*","where type='1' order by id").then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
      });
      
    },
    ambil(data) {
      this.dataedit = data;
      this.cekstok();
      this.skip_ambil_diskon=true;
      console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    bayar(){
        if(confirm('Apakah yakin di proses ?')){
            this.dataedit.total=this.total;
            this.dataedit.uang=this.uang
            this.dataedit.id_user=this.$store.state.users.id
            let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
            delete this.dataedit.id_produk
            delete this.dataedit.status
            this.dataedit.status='3'
            this.dataedit.list_id=JSON.stringify(data)
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            this.dataedit.id=this.id_nota;
            sdb.collection('app_kasir_transaksi_nota').doc().set(this.dataedit).then(res=>{
                this.$router.push(`/admin/report/transaksi_now?nota=${this.dataedit.nota}`)
                // sdb.collection("app_kasir_keranjang",false).doc().select(`update app_kasir_keranjang set posisi='nota' WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                // });
            })
        }
    },
    submit() {
      let that=this
      this.dataedit.status='keluar'
      this.dataedit.tanggal_w=this.format(new Date(this.dataedit.tanggal));
      this.dataedit.id_user=this.$store.state.users.id
      this.dataedit.posisi='nota'
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di dataedit
          delete this.dataedit.id;
          sdb
            .collection("app_kasir_keranjang")
            .doc()
            .set(this.dataedit)
            .then((res) => {
              that.getData();
              that.cekstok();
              
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_keranjang")
            .doc()
            .set(this.dataedit)
            .then((res) => {
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_keranjang")
            .doc(this.dataedit.id)
            .delete()
            .then((res) => {
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
      setTimeout(() => {
        this.getNota();
        this.getData();
    }, 1000);
    this.dataedit.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
  },
};
</script>